import {useLocalObservable} from 'mobx-react-lite';

import userServices from 'store/userServices';
import messagesServices from 'store/messagesServices';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Message} from 'postcss';
import {useRef} from 'react';
import appService from 'store/appService';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {
		getMessages,
		updateMessageFieldById,
		socketMessages,
		setSocketMessages,
		setChatScrollPosition,
		setLastViewedMessageProjects,
		setLastViewedMessageId,
	} = useLocalObservable(() => messagesServices);

	const {projectId} = useLocalObservable(() => appService);

	const isLoading = useRef<boolean>(false);

	const scrollToMessage = (
		messageID: number,
		chatScroll: HTMLDivElement | null,
		position?: string
	) => {
		const messageToScroll = document.querySelector(`.chat__message[data-id="${messageID}"]`);
		const messageLastChild = document.querySelector('.chat__message:last-child');
		let messageLastChildMarginBottom = 0;

		if (messageLastChild && getComputedStyle(messageLastChild)) {
			messageLastChildMarginBottom = parseInt(getComputedStyle(messageLastChild).marginBottom, 10);
		}

		if (chatScroll && messageToScroll && messageToScroll instanceof HTMLElement) {
			const calcScrollOffsetY =
				// eslint-disable-next-line no-nested-ternary
				position === 'center'
					? messageToScroll.offsetTop -
					  chatScroll.clientHeight / 2 +
					  messageToScroll.offsetHeight / 2
					: position === 'start'
					? messageToScroll.offsetTop
					: messageLastChildMarginBottom +
					  messageToScroll.offsetTop +
					  messageToScroll.clientHeight -
					  chatScroll.clientHeight;

			chatScroll?.scroll({
				top: calcScrollOffsetY,
			});
		}
	};

	const onReachBottomHandler = async (
		setLastViewedMessage: (val: number | null) => void,
		isNextMessages: boolean,
		setIsNextMessages: (val: boolean) => void,
		getNextMessages?: () => Promise<{status: 'success' | 'error'; data: any}>
	) => {
		if (getNextMessages && !isLoading.current && isNextMessages) {
			// load next messages
			isLoading.current = true;
			const response = await getNextMessages();

			if (response.status === ResponseStatus.SUCCESS) {
				if (response.data.length === 0) {
					setChatScrollPosition(false);
					setLastViewedMessage(null);
					setIsNextMessages(false);
					setLastViewedMessageId(null);
					setSocketMessages([]);
				}
				isLoading.current = false;
			}
			return;
		}

		setChatScrollPosition(false);
		setSocketMessages([]);
		// sliceMessages();
	};

	const getLastViewedMessage = (clientHeight: number, scrollTop: number) => {
		getMessages.forEach(el => {
			const selectedMessage = document.querySelector(`.chat__message[data-id="${el.id}"]`);
			if (selectedMessage && selectedMessage instanceof HTMLElement) {
				const {offsetTop, offsetHeight} = selectedMessage;
				const messageBorderBottom = offsetTop + offsetHeight;
				const scrollBorderBottom = scrollTop + clientHeight;

				if (messageBorderBottom <= scrollBorderBottom) {
					setLastViewedMessageProjects(el.id, projectId);
				}
			}
		});
	};

	const checkScrollTop = async (
		visiblePreloader: boolean,
		setVisiblePreloader: (val: boolean) => void,
		setLastViewedMessage: (val: number | null) => void,
		setNewMessagesCount: (val: number) => void,
		chatMessages: HTMLDivElement | null,
		getPreviousMessages: () => Promise<'success' | 'error'>,
		setChatScrollTop: (val: number) => void,
		event: React.BaseSyntheticEvent,
		isNextMessages: boolean,
		setIsNextMessages: (val: boolean) => void,
		getNextMessages?: () => Promise<{status: 'success' | 'error'; data: any}>
	) => {
		const {clientHeight, scrollTop, scrollHeight} = event.target;

		getLastViewedMessage(clientHeight, scrollTop);

		let countViewedMessages = 0;

		const unreadMessages = getMessages.filter(item => item.isUnread);
		unreadMessages.forEach(el => {
			const selectedMessage = document.querySelector(`.chat__message[data-id="${el.id}"]`);
			if (selectedMessage && selectedMessage instanceof HTMLElement) {
				const {offsetTop, offsetHeight} = selectedMessage;
				const messageBorderBottom = offsetTop + offsetHeight;
				const scrollBorderBottom = scrollTop + clientHeight;

				if (messageBorderBottom <= scrollBorderBottom) {
					updateMessageFieldById(el.id, {isUnread: false});
					// if (newMessagesCount) {
					countViewedMessages += 1;
					// }
				}
			}
		});

		const lastRenderedMsgIndex = socketMessages
			.map(msg => msg.id)
			.indexOf(getMessages[getMessages.length - 1].id);

		const allUnreaded = socketMessages.length
			? unreadMessages.length +
			  socketMessages.filter((el, index) => index > lastRenderedMsgIndex).length
			: unreadMessages.length;

		if (allUnreaded) {
			setNewMessagesCount(allUnreaded - countViewedMessages);
		}

		if (scrollTop + clientHeight - scrollHeight <= -50) {
			setChatScrollPosition(true);
		} else
			onReachBottomHandler(
				setLastViewedMessage,
				isNextMessages,
				setIsNextMessages,
				getNextMessages
			);

		if (scrollTop === 0 && accessToken && !isLoading.current) {
			isLoading.current = true;
			let initialHeight = 0;
			if (chatMessages) initialHeight = chatMessages.scrollHeight - chatMessages.scrollTop + 200;
			setVisiblePreloader(true);

			const response = await getPreviousMessages();
			if (response === ResponseStatus.SUCCESS) {
				setVisiblePreloader(false);
				isLoading.current = false;

				if (initialHeight && chatMessages)
					setChatScrollTop(chatMessages.scrollHeight - initialHeight);
			}
		}
	};

	const visibleMessageElements = (index: number, message: Message, messages: Message[]) => {
		const {talker, createdAt, room} = message;

		let visibleAvatar = true;
		let visibleHeader = true;
		let visibleDate = false;

		const nextMessage = messages[index + 1];
		const prevMessage = messages[index - 1];

		if (
			nextMessage &&
			nextMessage.talker?.id === talker?.id &&
			nextMessage.createdAt.split('T')[0] === createdAt.split('T')[0] &&
			nextMessage.room?.externalRoomId === room?.externalRoomId
		) {
			visibleAvatar = false;
		}

		if (prevMessage) {
			visibleDate = prevMessage.createdAt.split('T')[0] !== createdAt.split('T')[0];

			if (
				prevMessage.talker?.id === talker?.id &&
				!visibleDate &&
				prevMessage.room?.externalRoomId === room?.externalRoomId
			) {
				visibleHeader = false;
			}
		}

		return {visibleAvatar, visibleHeader, visibleDate};
	};

	return {
		scrollToMessage,
		checkScrollTop,
		visibleMessageElements,
	};
};
