import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import toastServices from 'store/toastServices';
import userServices from 'store/userServices';

import AdminProxyService from 'services/api/AdminProxyService';

import useL10n from 'l10n/useL10n';

export default () => {
	const translations = useL10n();

	const {accessToken, setAdminData, setAdmin} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);

	const getAdmins = async () => {
		const response = await AdminProxyService.getAdmins(accessToken);
		return response;
	};

	const getAdmin = async (id: number) => {
		const response = await AdminProxyService.getAdmin(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			setAdmin(response.data);
		}
	};

	const addAdmin = async (admin: any) => {
		const response = await AdminProxyService.addAdmin(admin, accessToken);
		return response;
	};

	const updateAdmin = async (adminData: {
		name?: string;
		chatName?: string;
		chatPic?: string;
		password?: string;
	}) => {
		const response = await AdminProxyService.updateAdmin(accessToken, adminData);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: '',
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: '',
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const updatePassword = async (oldPassword: string, password: string) => {
		const response = await AdminProxyService.updatePassword(accessToken, oldPassword, password);
		return response;
	};

	const updateProjectUser = async (id: number, adminData: {role: string}) => {
		const response = await AdminProxyService.updateProjectUser(accessToken, id, adminData);
		return response;
	};

	const deleteProjectUser = async (id: number) => {
		const response = await AdminProxyService.deleteProjectUser(id, accessToken);
		return response;
	};

	const getProjectName = async () => {
		const response = await AdminProxyService.getProjectName(accessToken);
		return response;
	};

	const updateProjectName = async (name: string) => {
		const response = await AdminProxyService.updateProjectName(accessToken, name);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: '',
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: '',
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	return {
		getAdmins,
		updateProjectUser,
		deleteProjectUser,
		getAdmin,
		addAdmin,
		updateAdmin,
		updatePassword,
		getProjectName,
		updateProjectName,
	};
};
