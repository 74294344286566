import {observer, useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {FunctionComponent, useState} from 'react';
import {Button, Modal, Form, FloatingLabel} from 'react-bootstrap';

import modalServices from 'store/modalServices';
import userServices from 'store/userServices';
import toastServices from 'store/toastServices';

import BanService from 'services/api/BanService';

import useL10n from 'l10n/useL10n';
import useBanReasons from 'hooks/useBanReasons';
import {BanReasonEnum} from 'models/enums/BanReason.enum';

const CreateBanModal: FunctionComponent = function CreateBanModal() {
	const {accessToken} = useLocalObservable(() => userServices);
	const {banModal, banModalVisible, hideBanModal} = useLocalObservable(() => modalServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {getReasons} = useBanReasons();

	const [reason, setReason] = useState(0);
	const [description, setdescription] = useState('');
	const [time, setTime] = useState('0,12');
	const [btnDisabeld, setBtnDisabled] = useState(false);
	const translations = useL10n();

	if (banModal === null) {
		return null;
	}

	const banTime = [
		{
			value: [0, 12],
			text: `12${translations.submenu.hours}`,
		},
		{
			value: [0, 24],
			text: `24${translations.submenu.hours}`,
		},
		{
			value: [0, 72],
			text: `72${translations.submenu.hours}`,
		},
		{
			value: [7, 0],
			text: `7${translations.submenu.days}`,
		},
		{
			value: [30, 0],
			text: `30${translations.submenu.days}`,
		},
		{
			value: null,
			text: translations.forever,
		},
	];

	const clear = () => {
		setdescription('');
		setReason(0);
		setTime('0,12');
		setBtnDisabled(false);
	};

	const showToaster = (responseText: string, responseStatus: string, name?: string | null) => {
		addToast({
			title: name ? translations.toasts.user(name) : '',
			text: responseText,
			variant: responseStatus === ResponseStatus.SUCCESS ? 'success' : 'danger',
		});
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setdescription(value);
	};

	const banUser = async () => {
		let responseText = '';
		type ban = {
			userId: number;
			value: boolean;
			externalRoomId?: string;
			expires?: string | null;
			description?: string;
			reason: number;
		};
		const banData: ban = {
			userId: banModal?.targetId,
			value: true,
			reason,
			externalRoomId: banModal.externalRoomId,
		};

		if (description !== '') banData.description = description;

		if (time) {
			const days = +time.split(',')[0];
			const hours = +time.split(',')[1];

			const date = new Date();
			date.setDate(date.getDate() + days);
			date.setHours(date.getHours() + hours);
			banData.expires = days + hours > 0 ? date.toISOString() : null;
		}

		const response = await BanService.toggleUserBan(banData, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			responseText = translations.toasts.userBanned;
			banModal?.getReports && banModal?.getReports();
		} else if (response.status === ResponseStatus.ERROR) responseText = translations.toasts.error;
		showToaster(responseText, response.status, banModal?.targetName);
		hideBanModal();
		clear();
	};

	const banTalker = async () => {
		let responseText = '';
		type ban = {
			talkerId: number;
			value: boolean;
			externalRoomId: string;
			expires?: string | null;
			description?: string;
			reason: number;
		};

		const banData: ban = {
			talkerId: banModal.targetId,
			value: true,
			externalRoomId: banModal.externalRoomId,
			reason,
		};

		if (description !== '') banData.description = description;

		if (time) {
			const days = +time.split(',')[0];
			const hours = +time.split(',')[1];

			const date = new Date();
			date.setDate(date.getDate() + days);
			date.setHours(date.getHours() + hours);
			banData.expires = days + hours > 0 ? date.toISOString() : null;
		}

		const response = await BanService.toggleTalkerBan(banData, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			responseText = translations.toasts.talkerBanned;
			banModal?.getReports && banModal?.getReports();
		} else if (response.status === ResponseStatus.ERROR) responseText = translations.toasts.error;
		showToaster(responseText, response.status, banModal?.targetName);
		hideBanModal();
		clear();
	};

	const ban = () => {
		setBtnDisabled(true);
		banModal?.type === 'banUser' ? banUser() : banTalker();
	};

	const onModalClose = () => {
		clear();
		hideBanModal();
	};

	const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setReason(+value);
		if (+value === BanReasonEnum.NICKNAME) setTime('0,0');
		else if (time === '0,0') setTime('0,12');
	};

	const onTimeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setTime(value);
	};

	return (
		<Modal
			show={banModalVisible}
			onClose={onModalClose}
			onHide={onModalClose}
			animation={false}
			centered>
			<Modal.Header closeButton>
				<Modal.Title> {translations.alerts.banTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					{banModal?.type === 'banUser'
						? translations.alerts.banUser(banModal?.targetName || '')
						: translations.alerts.banTalker(banModal?.targetName || '')}
				</p>
				<Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
					<FloatingLabel
						controlId='floatingSelect'
						label={translations.toasts.banReason}
						className='mb-2'>
						<Form.Select onChange={onSelect} value={reason}>
							{getReasons()
								.slice(0, -1)
								.map((item: {text: string; type: number}, index: number) => {
									return (
										<option value={item.type} key={index}>
											{item.text}
										</option>
									);
								})}
						</Form.Select>
					</FloatingLabel>

					{reason !== BanReasonEnum.NICKNAME && (
						<FloatingLabel
							controlId='floatingSelect'
							label={translations.table.timeBan}
							className='mb-2'>
							<Form.Select onChange={onTimeSelect} value={time}>
								{banTime.map((item: any, index: number) => {
									return (
										<option value={item.value} key={index}>
											{item.text}
										</option>
									);
								})}
							</Form.Select>
						</FloatingLabel>
					)}

					<Form.Control
						as='textarea'
						rows={5}
						size='sm'
						placeholder={translations.table.comment}
						value={description}
						onChange={onChangeHandler}
						maxLength={250}
					/>
				</Form.Group>
				<Modal.Footer>
					<Button variant='secondary' onClick={onModalClose}>
						{translations.btns.cancel}
					</Button>
					<Button variant='danger' onClick={ban} disabled={btnDisabeld}>
						{translations.alerts.banUser(banModal?.targetName || '').split(' ')[0]}
					</Button>
				</Modal.Footer>
			</Modal.Body>
		</Modal>
	);
};

export default observer(CreateBanModal);
